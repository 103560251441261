import React from 'react';
import Navbar from '../components/Navbar';
import '../styles/NewsPage.css'; // Asegúrate de tener este archivo CSS para aplicar estilos específicos
import meetingImage from '../img/Proter-Contactanos.png';
import { Button } from 'react-bootstrap';
import ContactSection from '../components/ContactSection';

// Importa las imágenes correspondientes
import img1 from '../img/Proter-AATI.png';
import img2 from '../img/proter-TJNE.png';
import img3 from '../img/proter-TEX.png';
import img4 from '../img/Proter-MAYSUN.png';
import img5 from '../img/proter-InnoEX.png';
import img6 from '../img/Proter-canton.png';



const News = () => {
  // Datos de ejemplo para cada noticia
  const newsData = [
    { category: 'AATI - China', title: 'Visita a la compañia AATI que es su socio estrategico para el mercado de Titanio', image: img1 },
    { category: 'TJNE - China', title: 'Vista de Proter a TJNE expertos en tecnologias de extraccion de Cobre', image: img2 },
    { category: 'Feria de Canton - China', title: 'Visita de Proter a Maysun lideres en equipos de corte para Copper Foil', image: img6 },
    { category: 'Tex Technology - Japón', title: 'Proter cierra acuerdo con Tex Technology, lideres en Copper Foil', image: img3 },
    { category: 'Maysun - Japon', title: 'Visita de Proter a Maysun lideres en equipos de corte para Copper Foil', image: img4 },
    { category: 'Feria InnoEX - Hong Kong', title: 'Proter visita InnoEX la feria de innovación mas importante de Hong Kong', image: img5 },
   
  ];

  return (
    <div>
      <Navbar />
      {/* Jumbotron */}
      <div className="jumbotron" id="jumbotron">
        <div className="jumbotron-content">
          <h1>Noticias</h1>
          <a href="/contacto" className="jumbotron-button">Conócenos</a>
        </div>
      </div>
      {/* Contenedor principal para la sección de noticias */}
      <section className="news-section">

        <div className="news-grid">
          {/* Mapear cada noticia para mostrarla como una tarjeta */}
          {newsData.map((news, index) => (
            <div key={index} className="news-card">
              <img src={news.image} alt={news.title} className="news-image" />
              <div className="news-content">
                <h4 className="news-category">{news.category}</h4>
                <h3 className="news-title">{news.title}</h3>
              </div>
            </div>
          ))}
        </div>
      </section>
      <ContactSection />
    </div>
  );
}

export default News;
