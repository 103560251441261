import React from 'react';
import { Button } from 'react-bootstrap';
import '../styles/Contact.css';
import meetingImage from '../img/Proter-Contactanos.png'; // Asegúrate de que la imagen esté en la carpeta correcta

const Contact = () => {
  return (
    <section id="contact" className="contact-section">
      <div className="container-fluid">
        <div className="row align-items-center">
          {/* Columna de la imagen */}
          <div className="col-md-6 p-0">
            <img src={meetingImage} alt="Reunión" className="img-fluid" />
          </div>
          {/* Columna de texto y botón */}
          <div className="col-md-6 p-5 contact-text">
            <h2>Programa hoy una reunión para conversar sobre tu proyecto</h2>
            <p>No esperes más para iniciar el camino hacia la transformación con Proter.</p>
            <a href="/contacto" className="jumbotron-button">Contáctanos</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
