import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { Button, Carousel } from 'react-bootstrap'; // Importar Carousel
import '../styles/productoID.css'; // Archivo CSS para la página
import meetingImage from '../img/Proter-Contactanos.png';
import TRLImage from '../img/Proter_TRL.png';
import PatenteImage from '../img/Proter_Patentes.png';
import img6 from '../img/Proter_mcoc.png';
import img7 from '../img/Proter_ewtech.png';
import img8 from '../img/Proter_placa_titanium.png';
import img9 from '../img/Proter_BarrasCobre.png';
import img10 from '../img/Proter_CopperFoil.png';
import ContactSection from '../components/ContactSection';

const ProductDataIng = [
  {
    title: 'MCOC Marco Corto Circuitador',
    description: 'El Marco Corto Circuitador MCOC, cuyo propósito es mantener el proceso productivo para la operación de Electro Obtención, permitiendo un incremento en la productividad.',
    image: img6,
    id: 'MCOC',
  },
  {
    title: 'Planta Modular EWTECH-LED',
    description: 'Nave de electro obtención de cobre modular EWTECH_LED, utiliza ánodos y cátodos convencionales, para la producción de 1000 TPA y más.',
    image: img7,
    id: 'EWTECH-LED',
  },
  {
    title: 'Placas de Titanio para plantas EW',
    description: 'Proveedor de Cátodos de titanio y acero inoxidable con altos estándares de calidad.',
    image: img8,
    id: 'placas-titanio',
  },
  {
    title: 'Sistema barras de cobre para EW',
    description: 'Servicios de ingeniería, suministro, fabricación y mecanizado de Barras de Cobre para Naves de Electro obtención.',
    image: img9,
    id: 'barras-cobre-EW',
  },
  {
    title: 'Estudios Proyectos Copper Foil',
    description: 'Desarrollo de estudios de prefactibilidad y factibilidad para plantas de Copper foil.',
    image: img10,
    id: 'copper-Foil',
  },
];

const ProductID = () => {
  const location = useLocation();

  // Desplazamiento a la sección correcta después de la navegación
  useEffect(() => {
    if (location.state?.sectionId) {
      const sectionId = location.state.sectionId;
      const section = document.getElementById(sectionId);
      if (section) {
        setTimeout(() => {
          const navbarHeight = document.querySelector('.navbar').offsetHeight;
          window.scrollTo({
            top: section.offsetTop - navbarHeight,
            behavior: 'smooth',
          });
        }, 200); // Pequeña espera para asegurar que la página haya cargado
      }
    }
  }, [location.state]);

  return (
    <div>
      {/* Barra de navegación */}
      <Navbar />

      {/* Jumbotron */}
      <div className="jumbotron">
        <div className="jumbotron-content">
          <h1>Productos e Investigación y desarrollo</h1>
          <a href="/contacto" className="jumbotron-button">Conócenos</a>
        </div>
      </div>

      {/* Sección de TRL y Patentes */}
      <section id="trl-patentes" className="ProductID-section my-5 py-1">
        <h2 className="ProductID-title">Investigación, Desarrollo e Innovación I+D+i</h2>
        <p className="ProductID-description">
          En Proter, desarrollamos nuestros proyectos con un enfoque en innovación que nos ha permitido producir diferentes tipos de soluciones de alta calidad, llegando hasta el estado de patentes internacionales.
        </p>
        <div className="container-fluid contact-trl-section">
          <div className="row align-items-center">
            {/* Columna del carrusel de imágenes */}
            <div className="col-md-6 p-4">
              <Carousel>
                <Carousel.Item>
                  <img className="d-block w-100" src={TRLImage} alt="Metodología TRL" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={PatenteImage} alt="Patentes Internacionales" />
                </Carousel.Item>
              </Carousel>
            </div>
            {/* Columna de texto y botón */}
            <div className="col-md-6 py-4 px-2 contact-text">
              <h3>Utilizamos la metodología TRL para el desarrollo de nuestros procesos de innovación e investigación</h3>
              <p>Esta metodología nos ha permitido realizar patentes en diferentes partes del mundo como Estados Unidos, Australia, China, Perú y Chile.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Sección de Productos e Investigación */}
      <section id="products-research" className="ProductID-section">
        <h2 className="ProductID-title">Conoce nuestros Productos y Desarrollos I+D+i</h2>
        <p className="ProductID-description">
          Desarrollamos nuestros proyectos con un enfoque en innovación e investigación impulsando el desarrollo de nuevos productos.
        </p>

        {/* Generar tarjetas horizontales basadas en los datos */}
        <div className="ProductID-grid">
          {ProductDataIng.map((serviceing, index) => (
            <div key={index} className="card mb-3 ProductID-card" id={serviceing.id}>
              <div className="row g-0">
                {/* Imagen del servicio */}
                <div className="col-md-4">
                  <img src={serviceing.image} className="img-fluid rounded-start" alt={serviceing.title} />
                </div>
                {/* Contenido del servicio */}
                <div className="col-md-8">
                  <div className="ProductIDcard-body">
                    <h5 className="ProductIDcard-title">{serviceing.title}</h5>
                    <p className="ProductIDcard-text">{serviceing.description}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      <ContactSection />
    </div>
  );
}

export default ProductID;
