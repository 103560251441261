import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import '../styles/Contacto.css';

const Contacto = () => {
  const [formData, setFormData] = useState({
    name: '',
    lastname: '',
    email: '',
    message: '',
  });

  const [formStatus, setFormStatus] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateForm = () => {
    const { name, lastname, email, message } = formData;
    if (!name || !lastname || !email || !message) {
      setFormStatus('Todos los campos son obligatorios.');
      return false;
    }
    if (!validateEmail(email)) {
      setFormStatus('Por favor, introduce un correo electrónico válido.');
      return false;
    }
    if (message.length < 10) {
      setFormStatus('El mensaje debe tener al menos 10 caracteres.');
      return false;
    }
    if (!/^[a-zA-Z\s]+$/.test(name)) {
      setFormStatus('El nombre solo puede contener letras y espacios.');
      return false;
    }
    if (!/^[a-zA-Z\s]+$/.test(lastname)) {
      setFormStatus('Los apellidos solo pueden contener letras y espacios.');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch('https://iigsa.cl/send_email.php', { // Cambia 'yourdomain.com' por tu dominio real
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(formData).toString(),
      });

      if (response.ok) {
        setFormStatus('¡Mensaje enviado correctamente!');
        setFormData({ name: '', lastname: '', email: '', message: '' });
      } else {
        setFormStatus('Error al enviar el mensaje. Inténtalo de nuevo.');
      }
    } catch (error) {
      console.log(error);
      setFormStatus('Error al enviar el mensaje. Inténtalo de nuevo.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="contacto-jumbotron-1">
        <div className="jumbotron-content-1">
          <div className="left-section">
            <h1>Únete a la transformación</h1>
            <p>Contáctanos para descubrir cómo nuestras soluciones pueden llevar tu negocio al siguiente nivel.</p>
            <a href='/servicios/ingenieria-construccion' className="cta-btn">Conoce nuestros servicios</a>
          </div>
          <div className="right-section">
            <form className="contact-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Nombre <span>*</span></label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Nombre"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastname">Apellidos <span>*</span></label>
                <input
                  type="text"
                  id="lastname"
                  name="lastname"
                  value={formData.lastname}
                  onChange={handleInputChange}
                  placeholder="Apellidos"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Correo electrónico</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="ejemplo@correo.es"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="message">Mensaje <span>*</span></label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  placeholder="Coméntanos cuál de nuestros productos o servicios te interesa."
                  required
                ></textarea>
              </div>
              <button type="submit" className="submit-btn" disabled={isSubmitting}>
                {isSubmitting ? 'Enviando...' : 'Enviar'}
              </button>
              <div className="form-status-container">
                {formStatus && <p className="form-status">{formStatus}</p>}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacto;

