import React, { useState } from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import proter_transparente from "../img/proter_transparente.png";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/Navbar.css';

const NavbarComponent = () => {
  const [showServicios, setShowServicios] = useState(false);
  const [showProductos, setShowProductos] = useState(false);
  const [showProyectos, setShowProyectos] = useState(false);
  const navigate = useNavigate(); // Hook para la navegación
  const location = useLocation(); // Hook para obtener la ubicación actual

  const handleMouseEnter = (setShow) => {
    setShow(true);
  };

  const handleMouseLeave = (setShow) => {
    setShow(false);
  };

  // Función para manejar la navegación a la página de servicios o productos y luego hacer scroll a la sección específica
  const handleNavigateAndScroll = (sectionId, targetPage = '/') => {
    if (location.pathname === targetPage) {
      // Si ya estamos en la página destino, desplazarnos a la sección
      handleScrollToSection(sectionId);
    } else {
      // Navegar a la página y luego realizar scroll con el sectionId
      navigate(targetPage, { state: { sectionId } });
    }
  };

  // Función para manejar el desplazamiento a la sección
  const handleScrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    const navbarHeight = document.querySelector('.navbar')?.offsetHeight || 0;
    if (section) {
      window.scrollTo({
        top: section.offsetTop - navbarHeight, // Ajustar para evitar superposición con la navbar
        behavior: 'smooth',
      });
    }
  };

  return (
    <Navbar collapseOnSelect expand="lg" bg="light" variant="light" className="custom-navbar fixed-top">
      <Container fluid>
        <Navbar.Brand href="/" className="custom-navbar-brand">
          <img src={proter_transparente} alt="icon" className="navbar-icon" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            {/* Manejar clic en Inicio */}
            <Nav.Link
              as={Link}
              to="/"
              onClick={(e) => {
                e.preventDefault();
                handleNavigateAndScroll('home', '/');
              }}
            >
              Inicio
            </Nav.Link>

            {/* Manejar clic en Nosotros */}
            <Nav.Link
              as={Link}
              to="/nosotros"
              onClick={(e) => {
                e.preventDefault();
                handleNavigateAndScroll('jumbotron', '/nosotros');
              }}
            >
              Nosotros
            </Nav.Link>

            {/* Dropdown de Servicios */}
            <NavDropdown
              title="Servicios"
              id="collasible-nav-dropdown"
              show={showServicios}
              onMouseEnter={() => handleMouseEnter(setShowServicios)}
              onMouseLeave={() => handleMouseLeave(setShowServicios)}
              onClick={(e) => {
                e.preventDefault();
                handleNavigateAndScroll('jumbotron', '/servicios/ingenieria-construccion');
              }}
            >
              <NavDropdown.Item onClick={() => handleNavigateAndScroll('jumbotron', '/servicios/ingenieria-construccion')}>
                Nuestros Servicios
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNavigateAndScroll('proyectos-epc', '/servicios/ingenieria-construccion')}>
                Proyectos EPC
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNavigateAndScroll('proyectos-ingenieria', '/servicios/ingenieria-construccion')}>
                Proyectos de Ingeniería
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNavigateAndScroll('consultorias-eficiencia', '/servicios/ingenieria-construccion')}>
                Consultorías en Eficiencia Energética
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNavigateAndScroll('asesorias-puesta-marcha', '/servicios/ingenieria-construccion')}>
                Asesorías en Puesta en Marcha
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNavigateAndScroll('ingenieria-suministro', '/servicios/ingenieria-construccion')}>
                Ingeniería y Suministro Integral
              </NavDropdown.Item>
            </NavDropdown>

            {/* Dropdown de Productos */}
            <NavDropdown
              title="Productos I+D"
              id="collasible-nav-dropdown"
              show={showProductos}
              onMouseEnter={() => handleMouseEnter(setShowProductos)}
              onMouseLeave={() => handleMouseLeave(setShowProductos)}
              onClick={(e) => {
                e.preventDefault();
                handleNavigateAndScroll('jumbotron', '/productos/productos-id');
              }}
            >
              <NavDropdown.Item onClick={() => handleNavigateAndScroll('jumbotron', '/productos/productos-id')}>
                Investigación y Desarrollo
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNavigateAndScroll('MCOC', '/productos/productos-id')}>
                MCOC Marco Cortocircuitador
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNavigateAndScroll('EWTECH-LED', '/productos/productos-id')}>
                Planta Modular EWTECH-LED
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNavigateAndScroll('placas-titanio', '/productos/productos-id')}>
                Placas de Titanio para plantas EW
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNavigateAndScroll('barras-cobre-EW', '/productos/productos-id')}>
                Sistema Barras de Cobre para EW
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNavigateAndScroll('copper-Foil', '/productos/productos-id')}>
                Estudios Proyectos Copper Foil
              </NavDropdown.Item>
            </NavDropdown>

            {/* Dropdown de Proyectos */}
            <NavDropdown
              title="Proyectos"
              id="collasible-nav-dropdown"
              show={showProyectos}
              onMouseEnter={() => handleMouseEnter(setShowProyectos)}
              onMouseLeave={() => handleMouseLeave(setShowProyectos)}
              onClick={(e) => {
                e.preventDefault();
                handleNavigateAndScroll('jumbotron', '/proyectos/proyectos');
              }}
            >
              <NavDropdown.Item onClick={() => handleNavigateAndScroll('jumbotron', '/proyectos/proyectos')}>
                Proyectos destacados
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNavigateAndScroll('tobaku', '/proyectos/proyectos')}>
                Pucobre - Proyecto Tobaku
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNavigateAndScroll('iluminacion', '/proyectos/proyectos')}>
                BHP - Proyecto Iluminación patios MEL
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNavigateAndScroll('fotovoltaico-bess', '/proyectos/proyectos')}>
                Pucobre Tobaku - Proyecto Planta fotovoltaica BESS
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNavigateAndScroll('el-espino-2', '/proyectos/proyectos')}>
                Pucobre - Proyecto El Espino 2
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNavigateAndScroll('scrubber', '/proyectos/proyectos')}>
                BHP - Proyecto Scrubber laboratorio MEL
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNavigateAndScroll('el-espino', '/proyectos/proyectos')}>
                Pucobre - Proyecto El Espino
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNavigateAndScroll('quebrada-blanca-2', '/proyectos/proyectos')}>
                TECK Quebrada Blanca 2 - Proyecto TE1
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNavigateAndScroll('ctto-marco-bhp', '/proyectos/proyectos')}>
                BHP - Contrato marco Ingeniería
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNavigateAndScroll('sierra-atacama', '/proyectos/proyectos')}>
                Sierra Atacama - Proyecto Reacondicionamiento Sistema Control Planta
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNavigateAndScroll('Codelco-DCH', '/proyectos/proyectos')}>
              Codelco DCH - Proyecto Humos Negros
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNavigateAndScroll('Codelco-tripper', '/proyectos/proyectos')}>
              Codelco DCH - Proyecto Tripper Car
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNavigateAndScroll('Codelco-morgan', '/proyectos/proyectos')}>
              Codelco DCH - Proyecto Grua Morgan
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNavigateAndScroll('teno', '/proyectos/proyectos')}>
              Gasco - Proyecto EPC Planta Gas Teno
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNavigateAndScroll('ewtech', '/proyectos/proyectos')}>
              EWTECH-LED - Proyecto EPC planta Electrowinning modularo
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNavigateAndScroll('pampa-camarones', '/proyectos/proyectos')}>
              Pampa Camarones - Proyecto EPC Planta SX-EW-LIX
              </NavDropdown.Item>


            </NavDropdown>

            {/* Otras secciones */}
            <Nav.Link as={Link} to="/noticias">
              Noticias
            </Nav.Link>
            <Nav.Link as={Link} to="/contacto" className="custom-btn-link"> {/* Cambio aquí */}
              <button className="custom-btn">Contáctanos</button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
