import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar';
import '../styles/servicio-IngConst.css'; // Archivo CSS para la página
//import { Button } from 'react-bootstrap';
import meetingImage from '../img/Proter-Contactanos.png';
import img1 from '../img/Proter_ProyectosEPC.png';
import img2 from '../img/Proter_ProyectosdeIngeniería.png';
import img3 from '../img/Proter_ConsultoriasEficienciaEnergetica.png';
import img4 from '../img/Proter_AsesoriasPEM.png';
import img5 from '../img/Proter_IngenieríaSuministroIntegral.png';

const servicesDataIng = [
  {
    title: 'Proyectos EPC',
    description: 'Desarrollo de Proyectos de ingeniería, suministro y obras de construcción para proyectos mineros.',
    image: img1,
    id: 'proyectos-epc',
  },
  {
    title: 'Proyectos de Ingeniería',
    description: 'Desarrollo de Ingeniería integral para proyectos mineros, O&G y de Energía. En las siguientes disciplinas: Mecánica, Piping, Estructural, Civil, Eléctrica, Instrumentación y Control.',
    image: img2,
    id: 'proyectos-ingenieria',
  },
  {
    title: 'Consultorías en Eficiencia Energética',
    description: 'Auditoría y detección de anomalías en la operación de la planta minera con el objetivo de mejorar la eficiencia de corriente y aumentar la productividad.',
    image: img3,
    id: 'consultorias-eficiencia',
  },
  {
    title: 'Asesorías en Puesta en Marcha',
    description: 'Asesoría y acompañamiento en puestas en marcha, precomisionamiento y comisionamiento.',
    image: img4,
    id: 'asesorias-puesta-marcha',
  },
  {
    title: 'Ingeniería y Suministro Integral',
    description: 'Desarrollo de ingeniería, suministro y fabricación para operación minera.',
    image: img5,
    id: 'ingenieria-suministro',
  },
];

const EngineeringConstruction = () => {
  const location = useLocation();

  // Desplazamiento a la sección correcta después de la navegación
  useEffect(() => {
    if (location.state?.sectionId) {
      const sectionId = location.state.sectionId;
      const section = document.getElementById(sectionId);
      if (section) {
        setTimeout(() => {
          const navbarHeight = document.querySelector('.navbar').offsetHeight;
          window.scrollTo({
            top: section.offsetTop - navbarHeight,
            behavior: 'smooth',
          });
        }, 200); // Pequeña espera para asegurar que la página haya cargado
      }
    }
  }, [location.state]);

  return (
    <div>
      {/* Barra de navegación */}
      <Navbar />

      {/* Jumbotron */}
      <div className="jumbotron">
        <div className="jumbotron-content">
          <h1>Nuestros Servicios</h1>
          <a href="/contacto" className="jumbotron-button">Conócenos</a>
        </div>
      </div>

      {/* Sección de servicios con tarjetas horizontales */}
      <section id="servicesIngConst" className="servicesIngConst-section">
        <h2 className="servicesIngConst-title">Conoce nuestros servicios</h2>
        <p className="servicesIngConst-description">
          En Proter, enfrentamos desafíos con un equipo altamente capacitado, ofreciendo soluciones personalizadas para la complejidad única de su negocio.
          Contamos con experiencia comprobada en proyectos de ingeniería, construcción, eficiencia energética y gestión de la cadena de suministros, cumpliendo con los máximos estándares de calidad.
        </p>

        {/* Generar tarjetas horizontales basadas en los datos */}
        <div className="servicesIngConst-grid">
          {servicesDataIng.map((serviceing, index) => (
            <div key={index} className="card mb-3 servicesIngConst-card" id={serviceing.id}>
              <div className="row g-0">
                {/* Imagen del servicio */}
                <div className="col-md-4">
                  <img src={serviceing.image} className="img-fluid rounded-start" alt={serviceing.title} />
                </div>
                {/* Contenido del servicio */}
                <div className="col-md-8">
                  <div className="servicesIngConstcard-body">
                    <h5 className="servicesIngConstcard-title">{serviceing.title}</h5>
                    <p className="servicesIngConstcard-text">{serviceing.description}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Sección de CTA (Call to Action) */}
      <div className="container-fluid contact-section">
        <div className="row align-items-center">
          {/* Columna de la imagen */}
          <div className="col-md-6 p-0">
            <img src={meetingImage} alt="Reunión" className="img-fluid" />
          </div>
          {/* Columna de texto y botón */}
          <div className="col-md-6 p-5 contact-text">
            <h2>Programa hoy una reunión para conversar sobre tu proyecto</h2>
            <p>No esperes más para iniciar el camino hacia la transformación con Proter.</p>
            <a href="/contacto" className="jumbotron-button">Contáctanos</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EngineeringConstruction;
