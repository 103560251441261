import React from 'react';
import Navbar from './components/Navbar'; 
import Jumbotron from './components/Jumbotron'; 
import Services from './components/Services'; 
import Product from './components/Product'; 
import Projects from './components/Projects'; 
import Clients from './components/Clients'; 
import Contact from './components/Contact'; 
import Footer from './components/Footer'; 
import { Routes, Route } from 'react-router-dom';
import AboutUs from './pages/AboutUs';
import News from './pages/News';
import EngineeringConstruction from './pages/servicio-IngConst'; // Importa el nuevo componente de servicio
import ProductsID from './pages/ProductID'; // Importa el nuevo componente de servicio
import ProjectsPage from './pages/Projects'; // Importa el nuevo componente de servicio
import Contacto from './pages/Contacto'; // Importa el nuevo componente de servicio

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>  
        <Route
          path="/"
          element={
            <>
              <section id="home">
                <Jumbotron />
              </section>
              <section id="services">
                <Services />
              </section>
              <section id="products">
                <Product />
              </section>
              <section id="projects">
                <Projects />
              </section>
              <section id="clients">
                <Clients />
              </section>
              <section id="contact">
                <Contact />
              </section>
            </>
          }
        />
        <Route path="/nosotros" element={<AboutUs />} />
        <Route path="/noticias" element={<News />} />
        <Route path="/servicios/ingenieria-construccion" element={<EngineeringConstruction />} />
        <Route path="/productos/productos-id" element={<ProductsID />} />
        <Route path="/proyectos/proyectos" element={<ProjectsPage />} />
        <Route path="/contacto" element={<Contacto />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
