import React from 'react';
import '../styles/Services.css';

// Importa las imágenes directamente
import img1 from '../img/Proter_ProyectosEPC.png';
import img2 from '../img/Proter_ProyectosdeIngeniería.png';
import img3 from '../img/Proter_ConsultoriasEficienciaEnergetica.png';
import img4 from '../img/Proter_AsesoriasPEM.png';
import img5 from '../img/Proter_IngenieríaSuministroIntegral.png';

const servicesData = [
  {
    title: 'Proyectos EPC',
    description: 'Desarrollo de Proyectos de ingeniería, suministro y obras de construccion para proyectos mineros',
    image: img1,
  },
  {
    title: 'Proyectos de Ingeniería',
    description: 'Desarrollo de Ingeniería integral para proyectos mineros, O&G y de Energía. En las siguientes disciplinas: Mecánica, Piping, Estructural, Civil, Eléctrica, Instrumentación y Control',
    image: img2,
  },
  {
    title: 'Consultorias en Eficiencia energetica',
    description: 'Auditoria y detección de anomalías en la operación de la planta mineras con el objetivo mejorar la eficiencia de corriente y aumentar la productividad',
    image: img3,
  },
  {
    title: 'Asesorias en Puesta en Marcha',
    description: 'Asesoria y acompañamiento en puestas en marchas, precomisionamiento y comisionamiento',
    image: img4,
  },
  {
    title: 'Ingeniería y Suministro Integral',
    description: 'Desarrollo de ingeniería, suministro y fabricación para operación minera',
    image: img5,
  },
];

const Services = () => {
  return (
    <section id="services" className="services-section">
      <h2 className="services-title">Conoce nuestros servicios</h2>
      <p className="services-description">
        En Proter, enfrentamos desafíos con un equipo altamente capacitado, ofreciendo soluciones personalizadas para la complejidad única de su negocio.
        Contamos con experiencia comprobada en proyectos de ingeniería, construcción, eficiencia energética y gestión de la cadena de suministros cumpliendo con los máximos estándares de calidad.
      </p>
      <div className="services-grid">
        {servicesData.map((service, index) => (
          <div key={index} className="service-card">
            <img src={service.image} alt={service.title} className="service-image" />
            <div className="service-content">
              <h3 className="service-title">{service.title}</h3>
              <p className="service-description">{service.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Services;
