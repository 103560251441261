import React from 'react';
import '../styles/Projects.css';

// Importa las imágenes directamente
import img11 from '../img/Proter_Proyecto_Pucobre_Espino.png';
import img12 from '../img/Proter_Proyecto_Teck_QB2.png';
import img13 from '../img/Proter_Proyecto_EPC_PampaCamarones.png';
import img14 from '../img/Proter_Proyecto_BHP_CttoMarco.png';
import img15 from '../img/Proter_Proyectos_EPC_Teno.png';

const servicesData = [
  {
    title: 'Proyecto El Espino Pucobre (2024)',
    description: 'Desarrollo de ingenieria Electrica e Instrumentacion y control mediante metodologia BIM basado en un contrato marco',
    image: img11,
  },
  {
    title: 'Proyecto Quebrada Blanca 2 TECK',
    description: 'Desarrollo y preparación de permisos Eléctricos TE1 para ser presentado en SEC',
    image: img12,
  },
  {
    title: 'Proyecto EPC Pampa Camarones',
    description: 'Desarrollo de ingeniería, suministro y obras eléctricas, instrumentación, control y comunicación',
    image: img13,
  },
  {
    title: 'Contrato marco Ingeniería BHP',
    description: 'Servicios de ingeniería para Minera Escondida y Pampa Norte',
    image: img14,
  },
  {
    title: 'Proyecto EPC Planta Gas Teno Gasco',
    description: 'Desarrollo de ingeniería de datalle, suministro, fabricación y construcción para oplanta de gas.',
    image: img15,
  },
];

const Services = () => {
  return (
    <section id="services" className="services-section">
      <h2 className="services-title">Conoce nuestros proyectos destacados</h2>
      <p className="services-description">
        Proter ha desarrollado diferentes tipos de proyectos para multiples industrias como mineria, O&G y electrica distacando los siguientes proyectos:
      </p>
      <div className="services-grid">
        {servicesData.map((service, index) => (
          <div key={index} className="service-card">
            <img src={service.image} alt={service.title} className="service-image" />
            <div className="service-content">
              <h3 className="service-title">{service.title}</h3>
              <p className="service-description">{service.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
    
  );
}

export default Services;
