import React from 'react';
import '../styles/Jumbotron.css'; // Asegúrate de que esta hoja de estilos esté correctamente vinculada

const Jumbotron = () => {
  return (
    <div className="jumbotron">
      <div className="jumbotron-content">
        <h1>Soluciones integrales</h1>
        <p>
        Proter es una empresa de ingeniería y consultoría que desarrolla proyectos para la industria minera, O&G y energía. 
        Además, cuenta con múltiples desarrollos en el área de la innovación. Nuestro equipo esta dedicado a proporciona 
        soluciones innovadoras y sostenibles que impulsan el crecimiento y el éxito, ayudando a nuestros clientes 
        a establecer nuevos estándares en la industria.
        </p>
        <a href="/contacto" className="jumbotron-button">Descubre como</a>
      </div>
    </div>
  );
};

export default Jumbotron;