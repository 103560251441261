import React from 'react';
import meetingImage from '../img/Proter-Contactanos.png';

const ContactSection = () => (
  <div className="container-fluid contact-section">
    <div className="row align-items-center">
      <div className="col-md-6 p-0">
        <img src={meetingImage} alt="Reunión" className="img-fluid" />
      </div>
      <div className="col-md-6 p-5 contact-text">
        <h2>Programa hoy una reunión para conversar sobre tu proyecto</h2>
        <p>No esperes más para iniciar el camino hacia la transformación con Proter.</p>
        <a href="/contacto" className="jumbotron-button">Contáctanos</a>
      </div>
    </div>
  </div>
);

export default ContactSection;