import React from 'react';
import '../styles/Footer.css';
import { FaLinkedin, FaEnvelope, FaYoutube, FaTwitter } from 'react-icons/fa'; // Importa los íconos correctos
import proterLogo from '../img/proter_transparente.png'; // Asegúrate de que la ruta de la imagen sea correcta

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          {/* Logo */}
          <div className="footer-left">
            <img src={proterLogo} alt="Proter" className="footer-logo" />
          </div>

          {/* Navegación */}
          <div className="footer-center">
            <ul className="footer-nav">
              <li><a href="/">Inicio</a></li>
              <li><a href="/contacto">Contacto</a></li>
              <li><a href="/nosotros">Nosotros</a></li>
              <li><a href="/politicas">Políticas de Privacidad</a></li>
            </ul>
          </div>

          {/* Información de contacto */}
          <div className="footer-right">
            <p>proter@proter.cl</p>
            <p>(+56) 22964 4060</p>
            <p>Alonso de Córdova 5320 Oficina 1701-A Edificio Parque Oriente, Las Condes – Santiago</p>
            <p>Chile</p>
          </div>

          {/* Íconos sociales */}
          <div className="footer-social">
            <a href="https://www.linkedin.com/in/protersa/?originalSubdomain=cl" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
            <a href="https://www.youtube.com/channel/UCwPazfZAhSVDAUUCpaY4sew" target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
            <a href="https://x.com/protersa" target="_blank" rel="noopener noreferrer"><FaTwitter /></a> 
            <a href="mailto:proter@proter.cl"><FaEnvelope /></a>
          </div>
        </div>

        {/* Copyright */}
        <div className="footer-bottom">
          <p>Copyright © 2024 by Proter / Fourdplan - Digital Solutions</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
