import React from 'react';
import '../styles/Product.css';

// Importa las imágenes directamente
import img6 from '../img/Proter_mcoc.png';
import img7 from '../img/Proter_ewtech.png';
import img8 from '../img/Proter_placa_titanium.png';
import img9 from '../img/Proter_BarrasCobre.png';
import img10 from '../img/Proter_CopperFoil.png';

const servicesData = [
  {
    title: 'MCOC Marco Corto Circuitador',
    description: 'El Marco Corto Circuitador MCOC, cuyo propósito es mantener el proceso productivo para la operación de Electro Obtención, permitiendo un incremento en la productividad.',
    image: img6,
  },
  {
    title: 'Planta Modular EWTECH-LED',
    description: 'Nave de electro obtención de cobre modular  EWTECH_LED, utiliza ánodos y cátodos convencionales, para la producción de 1000 TPA y más.',
    image: img7,
  },
  {
    title: 'Placas de Titanio para plantas EW',
    description: 'Proveedor de Cátodos de titanio y acero inoxidable con altos estándares de calidad',
    image: img8,
  },
  {
    title: 'Sistema barras de cobre para EW',
    description: 'Servicios de ingeniería, suministro, fabricación y mecanizado de Barras de Cobre para Naves de Electro obtención',
    image: img9,
  },
  {
    title: 'Estudios Proyectos Copper Foil',
    description: 'Desarrollo de ingeniería, suministro y fabricación para operación minera',
    image: img10,
  },
];

const Services = () => {
  return (
    <section id="services" className="services-section">
      <h2 className="services-title">Conoce nuestros productos e innovación I+D</h2>
      <p className="services-description">
        En Proter, desarrollamos nuestros proyectos con un enfoque en innovación que nos ha permitido produccir diferentes tipos de soluciones de alta calidad, llegando hasta el estado de patentes internacionales.
      </p>
      <div className="services-grid">
        {servicesData.map((service, index) => (
          <div key={index} className="service-card">
            <img src={service.image} alt={service.title} className="service-image" />
            <div className="service-content">
              <h3 className="service-title">{service.title}</h3>
              <p className="service-description">{service.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Services;
