import React from 'react';
import Navbar from '../components/Navbar';
import '../styles/AboutUs.css'; // Importamos el archivo CSS
import proter_transparente from "../img/proter_transparente.png"; // Asegúrate de que la ruta sea correcta
import proter_iso from "../img/Proter_ISO9001.png"; // Asegúrate de que la ruta sea correcta
//import { Button } from 'react-bootstrap';
//import meetingImage from '../img/Proter-Contactanos.png'; // Asegúrate de que la imagen esté en la carpeta correcta
import ContactSection from '../components/ContactSection';

const AboutUs = () => {
  return (
    <div>
      {/* Barra de navegación */}
      <Navbar />

      {/* Jumbotron */}
      <div className="jumbotron">
        <div className="jumbotron-content">
          <h1>Acerca de nosotros</h1>
          <a href="/contacto" className="jumbotron-button">Conócenos</a>
        </div>
      </div>

      {/* Sección de Quiénes Somos */}
      <section className="aboutus-section">
        {/* Contenedor de texto */}
        <div className="aboutus-content">
          <h2 className="aboutus-title">¿Quiénes somos?</h2>
          <p className="aboutus-description">
            Desde 1999, ofrecemos servicios integrales e innovadores de alta calidad, diseñados para satisfacer las demandas y necesidades de nuestros clientes internos y externos. Nuestro compromiso con la excelencia está respaldado por la certificación ISO 9001:2015 de nuestro sistema de gestión de calidad.
          </p>
        </div>
        {/* Contenedor de la imagen */}
        <div className="aboutus-image">
          <img src={proter_transparente} alt="Proter Logo" />
        </div>
      </section>

      {/* Sección de Visión y Misión */}
      <div className="vision-mision">
        <div className="vision">
          <h3>Visión</h3>
          <p>
            Ser un referente en la creación y desarrollo de Proyectos Innovadores, Estratégicos y de Valor Compartido para la Industria Minería y Energía.
          </p>
        </div>
        <div className="mision">
          <h3>Misión</h3>
          <p>
            Entregar servicios y soluciones innovadoras introduciendo nuevas tecnologías en los procesos de la industria, minería y energía; a través de la creación de nuevos negocios brindando un servicio integral y diferenciador al cliente para satisfacer sus necesidades, trabajando con calidad y excelencia.
          </p>
        </div>
      </div>

      {/* Sección de Somos ISO9001 */}
      <section className="aboutus-section">
        {/* Contenedor de texto */}
        <div className="aboutus-content">
          <h2 className="aboutus-title">Somos ISO9001:2015</h2>
          <p className="aboutus-description">
            Para demostrar nuestro compromiso con los estándares de calidad exigidos por nuestros clientes, es que contamos con la certificación de la norma ISO 9001: 2015. Ofrecemos servicios integrales e innovadores de alta calidad, diseñados para satisfacer las demandas y necesidades de nuestros clientes internos y externos. Nuestro compromiso con la excelencia está respaldado por la certificación ISO 9001:2015 de nuestro sistema de gestión de calidad.
          </p>
        </div>
        {/* Contenedor de la imagen */}
        <div className="aboutus-image-ISO">
          <img src={proter_iso} alt="Proter ISO" />
        </div>
      </section>

      {/* Sección de Valores */}
      <div className="valores">
        <h2>Nuestros Valores</h2>
        <p>
          Compromiso con nuestros clientes, rigurosidad y excelencia, Lealtad y Confidencialidad, Toma de decisiones ágiles y rápida solución, Innovación, Respuesta de post venta, Procesos certificados internacionalmente.
        </p>
      </div>

      <ContactSection />
      
    </div>
  );
}

export default AboutUs;
