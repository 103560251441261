import React from 'react';
import { Carousel } from 'react-bootstrap';
import '../styles/Clients.css';

// Asegúrate de que las imágenes estén en la carpeta correcta
import bhp from '../img/Cliente-BHP.png';
import AntofagastaMinerals from '../img/Cliente-AntofagastaMinerals.png';
import PampaCamarones from '../img/Cliente-PampaCamarones.png';
import Codelco from '../img/Cliente-Codelco.png';
import Teck from '../img/Cliente-teck.png';
import SierraAtacama from '../img/Cliente-SierraAtacama.png';
import Pucobre from '../img/Cliente-pucobre.png';
import Inersa from '../img/Cliente-Inersa.png';
import AngloAmerican from '../img/Cliente-AngloAmerican.png';

// Agrupa los logos en un array
const logos = [
    bhp,
    AntofagastaMinerals,
    Codelco,
    Teck,
    PampaCamarones,
    SierraAtacama,
    Pucobre,
    Inersa,
    AngloAmerican,

];

const Clients = () => {
  // Divide los logos en grupos de tres
  const groupedLogos = [];
  for (let i = 0; i < logos.length; i += 3) {
    groupedLogos.push(logos.slice(i, i + 3));
  }

  return (
    <section id="clients" className="clients-section">
      <div className="container">
        <h2 className="clients-title">Nuestros clientes</h2>
        <p className="clients-description">
          En Proter, enfrentamos desafíos con un equipo altamente capacitado, ofreciendo soluciones personalizadas para la complejidad única de tu negocio. Con experiencia comprobada, transformamos productos y empresas en entidades altamente adaptables al entorno digital.
        </p>
        <Carousel 
          className="clients-carousel" 
          interval={2000}  // Intervalo de 3 segundos
          controls={true} 
          indicators={true} 
          slide={true}
        >
          {groupedLogos.map((group, index) => (
            <Carousel.Item key={index}>
              <div className="d-flex justify-content-around">
                {group.map((logo, idx) => (
                  <img
                    key={idx}
                    className="d-block"
                    src={logo}
                    alt={`Logo ${idx + 1}`}
                    style={{ maxWidth: '30%', height: 'auto' }}  // Ajusta el tamaño para que se vea bien
                  />
                ))}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </section>
  );
}

export default Clients;
