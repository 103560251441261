import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar';
import '../styles/ProjectsPage.css'; // Archivo CSS para la página
//import { Button } from 'react-bootstrap';
//import meetingImage from '../img/Proter-Contactanos.png';
import img11 from '../img/Proter_Proyecto_Pucobre_Espino.png';
import img12 from '../img/Proter_Proyecto_Teck_QB2.png';
import img13 from '../img/Proter_Proyecto_EPC_PampaCamarones.png';
import img14 from '../img/Proter_Proyecto_BHP_CttoMarco.png';
import img15 from '../img/Proter_Proyectos_EPC_Teno.png';
import img16 from '../img/Proter_Proyecto_SierraAtacama.png';
import img17 from '../img/Proter_Proyectos_Codelco_HumosNegro.png';
import img18 from '../img/Proter_Proyecto_EWTECH_LED.png';
import img19 from '../img/Proter_Proyectos_Codelco_TripperCar.png';
import img20 from '../img/Proter_Proyectos_Codelco_grua_morgan.jpg';
import img21 from '../img/Proter_Proyecto_Pucobre_Espino_2.png';
import ContactSection from '../components/ContactSection';
const Proyectos= [
  {
    title: 'Pucobre - Proyecto Tobaku (2024)',
    description: 'Contrato de asesoria de ingeniería contraparte en las disciplinas Eléctrica control & instrumentaciónía a traves de un contrato marco para el cliete Pucobre.',
    image: img11,
    id: 'tobaku', // ID único para cada proyecto
  },
  {
    title: 'BHP Minera Escondida - Proyecto Iluminación patios MEL (2024) ',
    description: 'Desarrollo de servicios de ingeniería de iluminación para los patios y bodegas de Minera Escondida con el proposito de cumplir con la normativa ambiental y de seguridad laboral.',
    image: img14,
    id: 'iluminacion', // ID único para cada proyecto
  },
  {
    title: 'Pucobre Tobaku - Proyecto Planta fotovoltaica BESS (2024)',
    description: 'Desarrollo de ingeniería de prefactibilidad para una planta fotovoltaica con sistema de almacenaje baterias BESS (Battery Energy Storage Systems).',
    image: img11,
    id: 'fotovoltaico-bess', // ID único para cada proyecto
  },
  {
    title: 'Pucobre - Proyecto El Espino 2 (2024)',
    description: 'Desarrollo de ingeniería eléctrica e instrumentación & control para la Planta de molienda y flotacion mediante metodología BIM (Buildeing Information Modeling) y AWP (Advanced Work Packaging) a través de un contrato marco para el cliente Pucobre.',
    image: img21,
    id: 'el-espino-2', // ID único para cada proyecto
  },
  {
    title: 'BHP Minera Escondida - Proyecto Scrubber laboratorio (2023) ',
    description: 'Desarrollo de servicios de ingeniería de detalle y de enlace para la planta de Chancado mediante la integracion de scrubber para la extracción de gases del laboratorio de Minera Escondida mediante metodología BIM (Buildeing Information Modeling), nube de punto y AWP (Advanced Work Packaging).',
    image: img14,
    id: 'scrubber', // ID único para cada proyecto
  },
  {
    title: 'Pucobre - Proyecto El Espino (2023)',
    description: 'Desarrollo de ingeniería eléctrica e instrumentación & control mediante metodología BIM (Buildeing Information Modeling) y AWP (Advanced Work Packaging) a través de un contrato marco para el cliente Pucobre.',
    image: img11,
    id: 'el-espino', // ID único para cada proyecto
  },
  {
    title: 'TECK Quebrada Blanca 2 - Proyecto TE1 (2023)',
    description: 'Desarrollo y preparación de carpetas de permisos Eléctricos TE1 del proyecto QB2 para ser presentado en la Superintendencia de Electricidad y Combustibles SEC para cumplir con la normativa vigente.',
    image: img12,
    id: 'quebrada-blanca-2', // ID único para cada proyecto
  },
  {
    title: 'BHP - Contrato marco Ingeniería  (2023)',
    description: 'Adjudicación del contrato marco para el "Desarrollo de servicios de ingeniería" para las compañias Mineras Escondida y Pampa Norte de BHP.',
    image: img14,
    id: 'ctto-marco-bhp', // ID único para cada proyecto
  },
  {
    title: 'Sierra Atacama - Proyecto Reacondicionamiento Sistema Control Planta (2022)',
    description: 'Desarrollo de ingeniería de detalle, compra y gestión de suministro, fabricación y construcción de una planta de generación de energía a gas perteneciente al cliente Gasco.',
    image: img16,
    id: 'sierra-atacama', // ID único para cada proyecto
  },
  {
    title: 'Codelco DCH - Proyecto Humos Negros (2021)',
    description: 'Desarrollo de ingeniería de detalle, compra y gestión de suministro, fabricación para la correa transportador de la Fundicion concentrado proyecto normalización emisiones de humos negros, material particulado y gases fugitivos Ingeniería y suministro de correa transportadora Tag nº5111-CRT-3003.',
    image: img17,
    id: 'Codelco-DCH', // ID único para cada proyecto
  },
  {
    title: 'Codelco DCH - Proyecto Tripper Car (2020)',
    description: 'Desarrollo de ingeniería mediante nube de puntos y modelación BIM , gestión de suministro de los tableros de control y fuerza, además de la  instrumentación y equipos comunicación para la automatización del Tripper de concentrado de cobre ubicado en la fundición Chuquicamata de Codelco, logrando reconocimiento internacional por la innovación de crear un enlace remoto incluyendo un sistema de control e instrumentos de la industria 4.0.',
    image: img19,
    id: 'Codelco-tripper', // ID único para cada proyecto
  },
  {
    title: 'Codelco DCH - Proyecto Grua Morgan (2019)',
    description: 'Suministro y fabricación de cuatro tambores de izaje principal para Grúa Morgan capacidad de 100 Toneladas de la fundición división Chuquicamata.',
    image: img20,
    id: 'Cedelco-morgan', // ID único para cada proyecto
  },
  {
    title: 'Gasco - Proyecto EPC Planta Gas Teno (2018)',
    description: 'Desarrollo de ingeniería de detalle, compra y gestión de suministro, fabricación y construcción de una planta de para cuatro estanques monticulados de 30.000 gas licuado petróleo (GLP) y pruebas para la generación de energía a gas perteneciente al cliente Gasco.',
    image: img15,
    id: 'teno', // ID único para cada proyecto
  },
  {
    title: 'EWTECH-LED - Proyecto EPC planta Electrowinning modular (2017)',
    description: 'Desarrollo de ingeniería, adquisición y gestión de suministro, fabricación y construcción de Nave de electro-obtención de cobre modular EWTECH-LED para la producción de 1000 TPA.',
    image: img18,
    id: 'ewtech', // ID único para cada proyecto
  },
  {
    title: 'Pampa Camarones - Proyecto EPC Planta SX-EW-LIX  (2014-2016)',
    description: 'Desarrollo de ingeniería, suministro y obras eléctricas, instrumentación, control y comunicación, pruebas y puesta en marcha de las obras eléctricas en media y baja tensión, instrumentación, sistema de control y comunicación para la planta de catados de 8400 Toneladas/Año, en las áreas de Chancado, Lixiviación, SX, Tank Farm, Electro Obtención y Abastecimiento de aguas.',
    image: img13,
    id: 'pampa-camarones', // ID único para cada proyecto
  },
];

const Projects = () => {
  const location = useLocation();

  // Desplazamiento a la sección correcta después de la navegación
  useEffect(() => {
    if (location.state?.sectionId) {
      const sectionId = location.state.sectionId;
      const section = document.getElementById(sectionId);
      if (section) {
        setTimeout(() => {
          const navbarHeight = document.querySelector('.navbar').offsetHeight;
          window.scrollTo({
            top: section.offsetTop - navbarHeight,
            behavior: 'smooth',
          });
        }, 200); // Pequeña espera para asegurar que la página haya cargado
      }
    }
  }, [location.state]);

  return (
    <div>
      {/* Barra de navegación */}
      <Navbar />

      {/* Jumbotron */}
      <div className="jumbotron" id="jumbotron">
        <div className="jumbotron-content">
          <h1>Nuestros Proyectos</h1>
          <a href="/contacto" className="jumbotron-button">Conócenos</a>
        </div>
      </div>

      {/* Sección de Proyectos */}
      <section id="Projects" className="Projects-section">
        <h2 className="Projects-title">Conoce nuestros proyectos destacados</h2>
        <p className="Projects-description">
          Proter ha desarrollado diferentes tipos de proyectos para múltiples industrias como minería, O&G y eléctrica, destacando los siguientes proyectos:
        </p>

        {/* Generar tarjetas horizontales basadas en los datos */}
        <div className="Projects-grid">
          {Proyectos.map((proyecto, index) => (
            <div key={index} className="card mb-3 Projects-card" id={proyecto.id}>
              <div className="row g-0">
                {/* Imagen del servicio */}
                <div className="col-md-4">
                  <img src={proyecto.image} className="img-fluid rounded-start" alt={proyecto.title} />
                </div>
                {/* Contenido del servicio */}
                <div className="col-md-8">
                  <div className="Projectscard-body">
                    <h5 className="Projectscard-title">{proyecto.title}</h5>
                    <p className="Projectscard-text">{proyecto.description}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      <ContactSection />      
    </div>
  );
};

export default Projects;
